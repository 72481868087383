/* eslint-disable */

import { TimelineLite, TweenMax, TimelineMax } from 'gsap';
import ScrollMagic from 'scrollmagic';
import 'animation.gsap'

import Headroom from 'headroom.js/dist/headroom';

import 'mmenu-js/dist/mmenu.polyfills';
import 'mmenu-js/dist/mmenu';

// import lity from 'lity/dist/lity';

// import Cookies from 'js-cookie';

jQuery(document).ready(function ($) {

  function morphDropdown(element) {
    this.element = element;
    this.mainNavigation = this.element.find('.main-nav');
    this.mainNavigationItems = this.mainNavigation.find('.has-dropdown');
    this.dropdownList = this.element.find('.dropdown-list');
    this.dropdownWrappers = this.dropdownList.find('.dropdown');
    this.dropdownItems = this.dropdownList.find('.content');
    this.dropdownBg = this.dropdownList.find('.bg-layer');
    this.mq = this.checkMq();
    this.bindEvents();
  }

  morphDropdown.prototype.checkMq = function () {
    //check screen size
    var self = this;
    return window.getComputedStyle(self.element.get(0), '::before').getPropertyValue('content').replace(/'/g, "").replace(/"/g, "").split(', ');
  };

  morphDropdown.prototype.bindEvents = function () {
    var self = this;
    //hover over an item in the main navigation
    this.mainNavigationItems.mouseenter(function (event) {
      //hover over one of the nav items -> show dropdown
      self.showDropdown($(this));
    }).mouseleave(function () {
      setTimeout(function () {
        //if not hovering over a nav item or a dropdown -> hide dropdown
        if (self.mainNavigation.find('.has-dropdown:hover').length == 0 && self.element.find('.dropdown-list:hover').length == 0) self.hideDropdown();
      }, 50);
    });

    //hover over the dropdown
    this.dropdownList.mouseleave(function () {
      setTimeout(function () {
        //if not hovering over a dropdown or a nav item -> hide dropdown
        (self.mainNavigation.find('.has-dropdown:hover').length == 0 && self.element.find('.dropdown-list:hover').length == 0) && self.hideDropdown();
      }, 50);
    });

    //click on an item in the main navigation -> open a dropdown on a touch device
    this.mainNavigationItems.on('touchstart', function (event) {
      var selectedDropdown = self.dropdownList.find('#' + $(this).data('content'));
      if (!self.element.hasClass('is-dropdown-visible') || !selectedDropdown.hasClass('active')) {
        event.preventDefault();
        self.showDropdown($(this));
      }
    });

    //on small screens, open navigation clicking on the menu icon
    this.element.on('click', '.nav-trigger', function (event) {
      event.preventDefault();
      self.element.toggleClass('nav-open');
    });
  };

  morphDropdown.prototype.showDropdown = function (item) {
    this.mq = this.checkMq();
    if (this.mq == 'desktop') {

      var self = this;
      var selectedDropdown = this.dropdownList.find('#' + item.data('content')),
        selectedDropdownHeight = selectedDropdown.innerHeight(),
        selectedDropdownWidth = selectedDropdown.children('.content').innerWidth(),
        selectedDropdownLeft = (item.offset().left + item.innerWidth() / 2 - selectedDropdownWidth / 2) - $('.cd-morph-dropdown').offset().left;

      //update dropdown position and size
      this.updateDropdown(selectedDropdown, parseInt(selectedDropdownHeight), selectedDropdownWidth, parseInt(selectedDropdownLeft));
      //add active class to the proper dropdown item
      this.element.find('.active').removeClass('active');
      selectedDropdown.addClass('active').removeClass('move-left move-right').prevAll().addClass('move-left').end().nextAll().addClass('move-right');
      item.addClass('active');
      //show the dropdown wrapper if not visible yet
      if (!this.element.hasClass('is-dropdown-visible')) {
        setTimeout(function () {
          self.element.addClass('is-dropdown-visible');
        }, 10);
      }
    }
  };

  morphDropdown.prototype.updateDropdown = function (dropdownItem, height, width, left) {
    this.dropdownList.css({
      '-moz-transform': 'translateX(' + left + 'px)',
      '-webkit-transform': 'translateX(' + left + 'px)',
      '-ms-transform': 'translateX(' + left + 'px)',
      '-o-transform': 'translateX(' + left + 'px)',
      'transform': 'translateX(' + left + 'px)',
      'width': width + 'px',
      'height': height + 'px',
    });

    this.dropdownBg.css({
      '-moz-transform': 'scaleX(' + width + ') scaleY(' + height + ')',
      '-webkit-transform': 'scaleX(' + width + ') scaleY(' + height + ')',
      '-ms-transform': 'scaleX(' + width + ') scaleY(' + height + ')',
      '-o-transform': 'scaleX(' + width + ') scaleY(' + height + ')',
      'transform': 'scaleX(' + width + ') scaleY(' + height + ')',
    });
  };

  morphDropdown.prototype.hideDropdown = function () {
    this.mq = this.checkMq();
    if (this.mq == 'desktop') {
      this.element.removeClass('is-dropdown-visible').find('.active').removeClass('active').end().find('.move-left').removeClass('move-left').end().find('.move-right').removeClass('move-right');
    }
  };

  morphDropdown.prototype.resetDropdown = function () {
    this.mq = this.checkMq();
    if (this.mq == 'mobile') {
      this.dropdownList.removeAttr('style');
    }
  };

  var morphDropdowns = [];
  if ($('.cd-morph-dropdown').length > 0) {
    $('.cd-morph-dropdown').each(function () {
      //create a morphDropdown object for each .cd-morph-dropdown
      morphDropdowns.push(new morphDropdown($(this)));
    });

    var resizing = false;

    //on resize, reset dropdown style property
    updateDropdownPosition();
    $(window).on('resize', function () {
      if (!resizing) {
        resizing = true;
        (!window.requestAnimationFrame) ? setTimeout(updateDropdownPosition, 300) : window.requestAnimationFrame(updateDropdownPosition);
      }
    });

    function updateDropdownPosition() {
      morphDropdowns.forEach(function (element) {
        element.resetDropdown();
      });

      resizing = false;
    };
  }
});

export default {
  init() {
    // JavaScript to be fired on all pages

    new Mmenu(document.querySelector('#mobile-menu'),{
      "extensions": [
         "shadow-page",
         "position-right",
      ]
   });

    var headroom = new Headroom(document.querySelector('header'));
    headroom.init();

    var tl = new TimelineLite;
    tl.add(TweenLite.to('header .container', 1.5, { opacity: 1, visibility: "visible" }));
    tl.add(TweenLite.to('.landing > .container > div', 0.5, { opacity: 1, y: 5, visibility: "visible" }), "-=1");
    tl.add(TweenLite.to('.landing > .container a.button', 0.3, { opacity: 1, visibility: "visible" }), "-=1");

    var header_final_height;
    var logo_height;
    var logo_top;
    // var menu_position;
    // var top_nav_position;

    if ($(window).width() >= 1024) {
      header_final_height = 70;
      // menu_position = 10;
      // top_nav_position = 14;
      // gradient_cover_top = 55;
      logo_height = 45;
      logo_top = 10;
      // button_margin = 10;
    } else {
      header_final_height = 60;
      // menu_position = 5;
      // top_nav_position = 9;
      // gradient_cover_top = 45;
      logo_height = 38;
      logo_top = 10;
      // button_margin = 0;
    }

    var controller = new ScrollMagic.Controller();

    var header_actions = new TimelineLite();
    header_actions.add([
      TweenMax.to('header.animate', 0.2, { backgroundColor: '#fff', height: header_final_height, boxShadow: '0 3px 5px 0 rgba(0,0,0,.15)' }),
      TweenMax.to('header.animate a.logo-container img:first-of-type', 0.2, { opacity: '1' }, '-=0.2'),
      TweenMax.to('header.animate a.logo-container img+img', 0.2, { opacity: '0' }, '-=0.2'),
      TweenMax.to('header.animate .logo', 0.2, { top: logo_top, height: logo_height }, '-=0.2'),
      TweenMax.to('header.animate i', 0.2, { color: '#333' }, '-=0.2'),
      TweenMax.to('header.animate #menu-toggle span, header.animate #mobile-menu-toggle span', 0.2, { background: '#333' }, '-=0.2'),
      // TweenMax.to('#menu-toggle', 0.2, { top: '18px' }, '-=0.1'),
      TweenMax.to('header.animate .has-dropdown > a', 0.2, { color: '#333' }, '-=0.2'),
      // TweenMax.to('header.animate .button', 0.2, { top: '10px', background: 'linear-gradient(90deg, #f9b700 0, #ea4a36)' }, '-=0.1'),
    ]);
    new ScrollMagic.Scene({ offset: 50 }).setTween(header_actions).addTo(controller);

  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired

    $('.acf-map').each(function () {

      var styles = require('../map.styles.json');

      var styledMap = new google.maps.StyledMapType(styles, { name: "Styled Map" });

      var mapOptions = {
        scrollwheel: false,
        navigationControl: false,
        mapTypeControl: false,
        scaleControl: false,
        draggable: true,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        zoom: 12, //$('#services-map').data('zoom')
      };

      var map = new_map($(this), mapOptions);

      map.mapTypes.set('map_style', styledMap);
      map.setMapTypeId('map_style');

      var address = $(this).data("loc");
      var coordinates;

      var geocoder = new google.maps.Geocoder();
      geocoder.geocode({ 'address': address }, function (results, status) {
        if (status == google.maps.GeocoderStatus.OK) {
          map.setCenter(results[0].geometry.location);
        }
      });

    });

  },
};

function new_map(element, options) {

  var markers = element.find('.marker');

  // create map/markers
  var map = new google.maps.Map(element[0], options);
  map.markers = [];

  markers.each(function () {
    add_marker($(this), map);
  });

  return map;
}

function add_marker(marker, map) {

  var m = marker.data('loc');
  var lat = marker.data('lat');
  var lng = marker.data('lng');

  var icon = {
    url: '/wp-content/themes/kingschurch2020/dist/images/pin.svg',
    scaledSize: new google.maps.Size(20, 35),
    origin: new google.maps.Point(0, 0),
    anchor: new google.maps.Point(10, 35)
  };

  if (false) {

  } else {

    // var geocoder = new google.maps.Geocoder();

    // geocoder.geocode({ 'address': marker.data('loc') }, function (results, status) {

    //   if (status == google.maps.GeocoderStatus.OK) {
    //      map.setCenter(results[0].geometry.location);

    //     // create marker
    //     var marker = new google.maps.Marker({
    //       position: results[0].geometry.location,
    //       map: map,
    //       icon: icon
    //     });

    //   } else {

    //     if(lat && lng) {
    //       var marker = new google.maps.Marker({
    //         position: { lat: marker.data('lat'), lng: marker.data('lng') },
    //         map: map,
    //         icon: icon
    //       });
    //     }

    //   }

    // });

    if(lat && lng) {

      var contentString = marker.data('title');

      var infowindow = new google.maps.InfoWindow();

      var marker = new google.maps.Marker({
        position: { lat: parseFloat(lat), lng: parseFloat(lng) },
        map: map,
        icon: icon
      });

      google.maps.event.addListener(marker, 'mouseover', function () {
        infowindow.setContent(contentString);
        infowindow.open(this.getMap(), this);
      });

      google.maps.event.addListener(marker, 'mouseout', function() {
        infowindow.close();
    });
    
    }

  }

  // add to array
  map.markers.push(marker);

}